
const useIsIOS = () => {
  const iOS = () => {
    if (typeof navigator !== 'undefined') {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform);
    }
    return false;
  };

  return iOS;
};

export default useIsIOS;
