import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components
import { ButtonComponent, SlashContainer } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
import Link from 'next/link';

// Images
import { useMeasure, useToggle } from 'react-use';
import logo from '../../public/Images/logo.webp';
import arrow from '../../public/icons/arrow-big.svg';
import whiteArrow from '../../public/icons/arrow-big-white.svg';
import facebookIcon from '../../public/icons/facebook.svg';
import linkedinIcon from '../../public/icons/linkedIn.svg';
import instagramIcon from '../../public/icons/instagram.svg';

// Utils
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';
import ContactModal from '../Organisms/ContactModal';

// Hooks
import useLockBodyScrollNotIOS from '../../hooks/useLockBodyScrollNotIOS';

const Footer = ({
  clientItems, networkItems, networkBtn,
  facebook, instagram, twitter, mentions, legalItems, isMobile,
}) => {
  const [leftPanelRef, { height: leftPanelHeight }] = useMeasure();

  const [isButtonHover, setIsButtonHover] = useState(false);

  // modal related variables
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScrollNotIOS(locked);

  const toggleContactModal = (value) => {
    setIsContactModalOpen(value);
    toggleLocked();
  };

  const handleContactUs = () => toggleContactModal(true);

  const renderMobileFooter = () => (
    <div>
      <div className="d-flex f-row align-start justify-between f-wrap ph-6 pt-5">
        {/* logo column */}
        <div className="d-flex f-column justify-center align-center mh-2" style={{ maxWidth: '100%' }}>
          <div className="mb-5 mt-2">
            <Image className="mh-6" src={logo} width={290} height={290} alt="logo" />
          </div>
          <div
            onMouseLeave={() => setIsButtonHover(false)}
            onMouseEnter={() => setIsButtonHover(true)}
          >
            <ButtonComponent onClick={handleContactUs} theme="outline">
              <div
                className="d-flex justify-center align-center large-button button-w-250"
              >
                <span>{strings.contactUs}</span>
                <div className="mh-2">
                  {isButtonHover
                    ? <Image src={whiteArrow} width={30} alt="arrow" />
                    : <Image src={arrow} width={30} alt="arrow" />}
                </div>
              </div>
            </ButtonComponent>
          </div>
          <div className="d-flex f-row f-wrap align-center mt-5">
            <Link href={facebook}>
              <a className="mh-2"><Image alt="facebook" src={facebookIcon} width={30} /></a>
            </Link>
            <Link href={instagram}>
              <a className="mh-2"><Image alt="instagram" src={instagramIcon} width={30} /></a>
            </Link>
            <Link href={twitter}>
              <a className="mh-2"><Image alt="linkedin" src={linkedinIcon} width={30} /></a>
            </Link>
          </div>
        </div>
        {/* user space column */}
        <div className="d-flex f-column justify-center mh-2">
          <div>
            <h6 className="uppercase bold mh-0">{strings.userSpace}</h6>
          </div>
          <div>
            {clientItems?.showExtranet ? (
              <Link href={process.env.NEXT_PUBLIC_EXTRANET_URL}>
                <a style={{ opacity: 1 }}>
                  <ButtonComponent
                    onClick={() => {
                    }}
                    theme="black"
                  >
                    <div className="d-flex justify-center align-center button-w-250 large-button mh-3">
                      <span>{strings.connection}</span>
                      <div className="mh-2">
                        <Image alt="arrow" src={arrow} width={30} />
                      </div>
                    </div>
                  </ButtonComponent>
                </a>
              </Link>
            ) : null}
            <div className={`d-flex f-column ${clientItems?.showExtranet ? 'mv-4' : 'mb-4'}`}>
              {clientItems?.items?.map(({ label, link }) => (
                <Link href={link} key={`${label}-${link}`}>
                  <a className="grey-600-text mv-2 normal-weight">{label}</a>
                </Link>
              ))}
            </div>
          </div>

        </div>
      </div>
      <SlashContainer width="100vw" backgroundColor={colors.primaryRed} offset={20} position="top">
        <div className="d-flex justify-center align-center f-column pb-6">
          <h6 className="uppercase white-text">{strings.joinNetwork}</h6>
          <div>
            {networkBtn ? (
              <Link href={networkBtn}>
                <a>
                  <ButtonComponent onClick={() => {}} theme="black">
                    <div className="d-flex f-row align-center justify-center large-button button-w-250 mh-3 mv-2">
                      <div className="d-flex f-column align-start mr-4">
                        <span className="bold">{`${strings.pro} ?`}</span>
                        <span className="normal-weight">{`${strings.pleaseJoin}`}</span>
                      </div>
                      <div className="ml-2">
                        <Image alt="arrow" src={arrow} width={30} />
                      </div>
                    </div>
                  </ButtonComponent>
                </a>
              </Link>
            ) : null}
            <div className="d-flex f-column mt-3 mb-6 mh-5">
              {networkItems.map(({ label, link }) => (
                <Link href={link} key={`${label}-${link}`}>
                  <a className="white-text mv-2">{label}</a>
                </Link>
              ))}
            </div>
          </div>

          <div className="d-flex f-row f-wrap white-text align-center medium-text mh-4 pb-4">
            <span className="mv-2 white-text">{`${mentions}`}</span>
            {legalItems.map(({ label, link }) => (
              <Link href={link} key={`${label}-${link}`}>
                <a className="mv-2">{`- ${label} `}</a>
              </Link>
            ))}
          </div>
        </div>
      </SlashContainer>
    </div>
  );

  const renderDesktopFooter = () => (
    <div>
      <div className="absolute full-size absolute-left-top-corner">
        <SlashContainer width="60%" backgroundColor={colors.white} offset={25}>
          <div
            ref={leftPanelRef}
            className="d-flex f-row align-start justify-center f-wrap footer-left-panel pt-5"
          >
            {/* logo column */}
            <div className="d-flex f-column justify-center align-end mh-2">
              <div className="mh-6 mb-3 mt-2">
                <Image alt="logo" className="mh-6" src={logo} width={290} height={290} />
              </div>
              <div
                className="mh-6"
                onMouseEnter={() => setIsButtonHover(true)}
                onMouseLeave={() => setIsButtonHover(false)}
              >
                <ButtonComponent
                  onClick={handleContactUs}
                  theme="outline"
                >
                  <div
                    className="d-flex justify-center align-center large-button mh-2"
                  >
                    <span>{strings.contactUs}</span>
                    <div className="mh-2">
                      {isButtonHover
                        ? <Image alt="arrow" src={whiteArrow} width={20} />
                        : <Image alt="arrow" src={arrow} width={20} />}
                    </div>
                  </div>
                </ButtonComponent>
              </div>
              <div className="d-flex f-row f-wrap mh-2 align-center mt-5 mh-6">
                <div className="mh-3">
                  <Link href={facebook}>
                    <a className="mh-3"><Image alt="facebook" src={facebookIcon} width={30} /></a>
                  </Link>
                  <Link href={instagram}>
                    <a className="mh-3"><Image alt="instagram" src={instagramIcon} width={30} /></a>
                  </Link>
                  <Link href={twitter}>
                    <a className="mh-3"><Image alt="linkedin" src={linkedinIcon} width={30} /></a>
                  </Link>
                </div>
              </div>
            </div>
            {/* user space column */}
            <div className="d-flex f-column justify-center align-start mh-2">
              <div className="mt-6 mh-6">
                <h6 className="uppercase">{strings.userSpace}</h6>
              </div>
              {clientItems?.showExtranet ? (
                <div className="mh-6">
                  <Link href={process.env.NEXT_PUBLIC_EXTRANET_URL}>
                    <a style={{ opacity: 1 }}>
                      <ButtonComponent
                        onClick={() => {
                        }}
                        theme="black"
                      >
                        <div className="d-flex justify-center align-center large-button mh-3">
                          <span className="mr-4">{strings.connection}</span>
                          <div className="mr-2">
                            <Image alt="arrow" src={arrow} width={20} />
                          </div>
                        </div>
                      </ButtonComponent>
                    </a>
                  </Link>
                </div>
              ) : null}
              <div className="mb-6">
                <div className={`d-flex f-column ${clientItems?.showExtranet ? 'm-6' : 'mh-6 mb-6'}`}>
                  {clientItems?.items?.map(({ label, link }) => (
                    <Link href={link} key={`${label}-${link}`}>
                      <a className="grey-600-text mv-2">{label}</a>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SlashContainer>
      </div>
      <div
        className="d-flex f-column align-end pt-2"
        style={{ backgroundColor: colors.primaryRed, height: `calc(${leftPanelHeight}px + 3rem)`, paddingTop: 110 }}
      >
        <div className="footer-right-panel">
          <h6 className="uppercase white-text">{strings.joinNetwork}</h6>
          {networkBtn ? (
            <Link href={networkBtn}>
              <a>
                <ButtonComponent onClick={() => {}} theme="black">
                  <div className="d-flex f-row justify-start align-center large-button">
                    <div className="d-flex f-column align-start mr-4">
                      <span className="bold">{`${strings.pro} ?`}</span>
                      <span className="normal-weight">{`${strings.pleaseJoin}`}</span>
                    </div>
                    <div className="mr-2">
                      <Image alt="arrow" src={arrow} width={20} />
                    </div>
                  </div>
                </ButtonComponent>
              </a>
            </Link>
          ) : null}
          <div className="d-flex f-column mt-4">
            {networkItems.map(({ label, link }) => (
              <Link href={link} key={`${label}-${link}`}>
                <a className="white-text mv-2">{label}</a>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute d-flex f-row grey-300-text fgIndex align-center medium-text p-3 footer-legal-mentions">
        <span className=" ml-4 mr-2 grey-200-text">{`${mentions}`}</span>
        {legalItems.map(({ label, link }) => (
          <Link href={link} key={`${label}-${link}`}>
            <a className="m-2">{`- ${label}`}</a>
          </Link>
        ))}
        <Link href="/sitemap.html">
          <a className="m-2">{`- ${strings.sitemap}`}</a>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      {
        isContactModalOpen
          ? (
            <ContactModal
              closeModal={() => toggleContactModal(false, null)}
              source="NOUS CONTACTER"
            />
          )
          : null
      }
      <footer className="full-width relative">
        {
            isMobile ? renderMobileFooter() : renderDesktopFooter()
          }
      </footer>
    </>
  );
};

Footer.propTypes = {
  clientItems: PropTypes.shape(
    {
      items: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })),
      showExtranet: PropTypes.bool,
    },
  ).isRequired,
  networkItems: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })).isRequired,
  legalItems: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })).isRequired,
  networkBtn: PropTypes.string,
  facebook: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  mentions: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  isMobile: false,
  networkBtn: null,
};

export default Footer;
