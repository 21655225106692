import { useLockBodyScroll } from 'react-use';
import useIsIOS from './useIsIOS';

const useLockBodyScrollNotIOS = (params) => {
  const isIOS = useIsIOS();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return isIOS() ? () => {} : useLockBodyScroll(params);
};

export default useLockBodyScrollNotIOS;
