import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

const ReassuranceBanner = ({ items }) => (
  <div className="full-width d-flex f-row justify-around align-center grey-100-background f-wrap">
    {
                items.map((item) => (
                  <div
                    className="d-flex f-column align-center justify-center m-5 reassurance-block"
                    key={`${item.label}-${item.picto}`}
                  >
                    <Image src={`/icons/${item.picto}.svg`} alt={item.label} width={24} height={24} />
                    <p>{item.label}</p>
                  </div>
                ))
            }
  </div>
);

ReassuranceBanner.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, picto: PropTypes.string })).isRequired,
};

export default ReassuranceBanner;
