import client from './client';

const getContactSubjects = () => client.get('/site-contact-subjects');
const postContact = (form) => client.post('/site-contacts', form);
const postNetworkContact = (form) => client.post('/network-contacts', form);

export default {
  getContactSubjects,
  postContact,
  postNetworkContact,
};
