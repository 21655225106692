export const isCorrectEmail = (email) => {
  // eslint-disable-next-line max-len
  const emailFormat = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
  return emailFormat.test(email);
};

export const isCorrectPassword = (password) => {
  const passwordFormat = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  return passwordFormat.test(password);
};

export const validateNotEmptyField = (setError, value) => {
  setError(!value);
};

export const validateEmailField = (setError, value) => {
  setError(!isCorrectEmail(value));
};
