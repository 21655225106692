import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonComponent,
  ButtonWithArrow,
  CheckboxComponent,
  InputComponent,
  LoaderComponent,
  ModalTemplate,
  TextAreaComponent,
} from '@zolteam/axenergie-ui-library';
import { useMutation, useQuery } from 'react-query';
import ReCAPTCHA from 'react-google-recaptcha';
import Link from 'next/link';

// Constants
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';
// Hooks and service
import useIsMobile from '../../hooks/useIsMobile';
import ContactService from '../../services/api/ContactService';
// Utils
import { isCorrectEmail, validateNotEmptyField } from '../../utils/validators';

const ContactModal = ({
  closeModal,
  agencyId,
  logo,
  openingHours,
  agencyPhone,
  source,
  defaultSubject,
  sendDataLayerEvent,
}) => {
  const [showNumber, setShowNumber] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Form related states
  const [subject, setSubject] = useState(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [firstnameError, setFirstnameError] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [optinNewsletter, setOptinNewsletter] = useState(false);
  const [optinRgpd, setOptinRgpd] = useState(false);

  const recaptchaRef = useRef();

  const [isMobile] = useIsMobile();

  // Queries
  const getSubjects = useQuery('getSubject',
    () => ContactService.getContactSubjects());
  const postDemand = useMutation('postDemand', (form) => {
    if (agencyId) {
      return ContactService.postContact(form);
    }
    return ContactService.postNetworkContact(form);
  });

  useEffect(() => {
    setSubject(defaultSubject);
  }, [defaultSubject]);

  useEffect(() => {
    if (getSubjects.isError) {
      closeModal();
    }
  }, [getSubjects.isError]);

  useEffect(() => {
    if (postDemand.isError) {
      const formError = Object.values(postDemand?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      setErrorMessage(formError);
      const isZipcodeError = postDemand?.error?.response?.data?.code === 'not_supported_postal_code';
      if (isZipcodeError) {
        setFeedbackMessage(strings.errors.postDemandZipcode);
      }
      if (!isZipcodeError && !formError) {
        setFeedbackMessage(strings.errors.postDemand);
      }
    }
    if (postDemand.isSuccess) {
      setFeedbackMessage(strings.success.postDemand);
    }
  }, [postDemand.isError, postDemand.isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((agencyId && !subject)
          || nameError
          || firstnameError
          || emailError
          || addressError
          || zipcodeError
          || cityError
          || messageError
          || !optinRgpd) {
      return false;
    }

    if (sendDataLayerEvent) {
      sendDataLayerEvent(
        getSubjects?.data?.data?.siteContactSubjects.find(({ id }) => id === subject)?.name,
      );
    }
    return recaptchaRef?.current?.execute();
  };

  const onReCAPTCHAChange = async (code) => {
    if (!code) {
      return false;
    }
    await postDemand.mutate({
      agencyId: agencyId || undefined,
      siteSubjectId: subject || undefined,
      source,
      fullName: `${firstname} ${name}`,
      firstName: firstname,
      lastName: name,
      emailAddress: email,
      address,
      postalCode: zipcode,
      city,
      message,
      optInNewsletter: optinNewsletter,
      optInRGPD: optinRgpd,
      captchaResponse: code,
      phone,
    });
    return recaptchaRef?.current?.reset();
  };

  const renderModalTitle = () => (
    <div
      className="form-file-text-area-width"
      style={{ marginBottom: -50 }}
    >
      <p className="h1 medium-weight white-text">{agencyId ? strings.youContactUs : strings.contactAxenergieNetwork}</p>
      <p className="grey-400-text normal-weight regular-text">
        {
        agencyId
          ? strings.contactAgencySubtitle
          : (
            <span>
              <span>{strings.contactNetworkSubtitle}</span>
              <Link href="/trouver-mon-chauffagiste">
                <a>
                  <span className="underline">{strings.goTherePlease}</span>
                </a>
              </Link>
            </span>
          )
      }
      </p>
    </div>
  );

  return (
    <ModalTemplate
      closeModal={closeModal}
      title={renderModalTitle()}
    >
      { feedbackMessage
        ? (
          <div className={`p-3 ${isMobile ? 'ph-1' : 'ph-6'}`}>
            <p className="medium-weight h5 grey-800-text">{feedbackMessage}</p>
          </div>
        )
        : (
          <div
            className={`d-flew f-column p-3 ${isMobile ? '' : 'ph-6'}`}
            style={{ width: isMobile ? 'calc(100vw - 2rem)' : 1000 }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
              onChange={onReCAPTCHAChange}
            />
            {/* Agency info line */}
            {agencyId ? (
              <div className={`d-flex f-row f-wrap ${isMobile ? 'justify-center' : 'justify-between'} align-center`}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`
                ${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${logo}`}
                  alt="agency-logo"
                  height={80}
                  className="mv-4"
                />

                <div className="d-flex f-column mb-3">
                  <span className="grey-800-text h6 normal-weight">
                    {strings.openingHours}
                  </span>
                  <div
                    className="normal-weight medium-text grey-400-text mh-2 mv-2 wysiwyg-text"
                    style={{ maxWidth: 250 }}
                    dangerouslySetInnerHTML={{ __html: openingHours }}
                  />
                </div>
                <div>
                  <ButtonComponent onClick={() => setShowNumber(true)} theme="outline">
                    <div className="mv-2">
                      {showNumber ? agencyPhone : strings.seeNumber}
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            )
              : null}
            <div className="line grey-400-background opacity-01 mv-4 full-width" />
            <span className="grey-800-text h2 medium-weight mh-2">{strings.yourDemand}</span>
            {
              errorMessage
                ? <p className="h5 primary-red-text ml-4">{errorMessage}</p>
                : null
            }
            {/* form */}
            <div
              className="d-flex f-row f-wrap justify-center mt-3"
            >
              {agencyId ? (
                <div className="full-width d-flex f-row f-wrap justify-center">
                  {
                  getSubjects.isLoading
                    ? <LoaderComponent size={30} borderWidth={3} color={colors.grey800} />
                    : getSubjects?.data?.data?.siteContactSubjects?.map(({ id, name: subjectName }) => (
                      <div
                        className={`m-2 ${isMobile ? 'small-button-with-arrow' : null}`}
                        key={id}
                      >
                        <ButtonWithArrow
                          onClick={() => setSubject(id)}
                          isSelected={subject === id}
                          title={subjectName}
                        />
                      </div>
                    ))
                }
                </div>
              ) : null}

              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setFirstname}
                  id="user-firstname"
                  value={firstname}
                  label={strings.firstname}
                  isError={firstnameError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setFirstnameError, firstname)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setName}
                  id="user-name"
                  value={name}
                  label={strings.name}
                  isError={nameError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setNameError, name)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setPhone}
                  id="user-phone"
                  value={phone}
                  label={strings.phone}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setEmail}
                  id="user-mail"
                  value={email}
                  label={strings.email}
                  isError={emailError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => setEmailError(!isCorrectEmail(email))}
                />
              </div>
              <div className="form-file-text-area-width m-2">
                <InputComponent
                  onChange={setAddress}
                  id="user-address"
                  value={address}
                  label={strings.address}
                  isError={addressError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setAddressError, address)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setZipcode}
                  id="user-zipcode"
                  value={zipcode}
                  label={strings.zipcode}
                  isError={zipcodeError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setZipcodeError, zipcode)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setCity}
                  id="user-city"
                  value={city}
                  label={strings.city}
                  isError={cityError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setCityError, city)}
                />
              </div>
              <div className="form-file-text-area-width m-2">
                <TextAreaComponent
                  onChange={setMessage}
                  id="user-message"
                  value={message}
                  label={strings.message}
                  isError={messageError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setMessageError, message)}
                />
              </div>
              <div className="m-2 full-width ph-3">
                <CheckboxComponent
                  label={(
                    <span className="medium-text grey-400-text normal-weight">
                      {strings.optinNewsletter}
                    </span>
                )}
                  handleChange={() => setOptinNewsletter((state) => !state)}
                  id="optin-newsletter"
                  value={optinNewsletter}
                />
              </div>
              <div className="m-2 full-width ph-3">
                <CheckboxComponent
                  label={(
                    <span className="medium-text grey-400-text normal-weight">
                      {strings.optinRgpd}
                    </span>
                )}
                  handleChange={() => setOptinRgpd((state) => !state)}
                  id="optin-rgpd"
                  value={optinRgpd}
                />
              </div>
            </div>
            <div>
              {
            (agencyId && !subject)
            || !firstname
            || !optinRgpd
            || !name
            || !email
            || !address
            || !zipcode
            || !city
            || !message ? null
              : (
                <div className="ml-4 mv-4">
                  <ButtonComponent onClick={handleSubmit}>
                    <div className="m-2 ph-5">
                      {
                          postDemand?.isLoading ? <LoaderComponent size={30} borderWidth={3} color={colors.white} />
                            : <span>{strings.send}</span>
                        }
                    </div>
                  </ButtonComponent>
                </div>
              )
          }
              <p className="grey-400-text small-text normal-weight">
                {agencyId ? strings.contactAgencyRgpd : strings.contactNetworkRgpd}
                {agencyId ? (
                  <Link href="/politique-de-confidentialite">
                    <a
                      className="underline ml-1"
                    >
                      <span>{strings.rgpd.privacyPolicy}</span>
                    </a>
                  </Link>
                ) : null}
              </p>
            </div>
          </div>
        )}
    </ModalTemplate>
  );
};

ContactModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  agencyId: PropTypes.number,
  logo: PropTypes.string,
  openingHours: PropTypes.string,
  agencyPhone: PropTypes.string,
  defaultSubject: PropTypes.number,
  sendDataLayerEvent: PropTypes.func,
};

ContactModal.defaultProps = {
  defaultSubject: null,
  agencyId: null,
  logo: null,
  openingHours: null,
  agencyPhone: null,
  sendDataLayerEvent: null,
};

export default ContactModal;
